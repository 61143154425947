.container {
  height: 50px;
  margin: 10px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menuList {
  list-style: none;
  text-transform: uppercase;
  margin: 10px 10px 10px 60px;
  font-size: 24px;
  font-weight: 900;

  .spaceBelow {
    margin-bottom: 20px;
  }

  a {
    cursor: pointer;
    &:hover {
      color: #f00;
    }
  }
}

.menuButtonContainer {
  position: absolute;
  top: 0px;
  left: 0px;
  color: #000;
  z-index: 1000;
}

.menuButton {
  cursor: pointer;
  padding: 10px;
  background: #fff;
  margin: 10px;
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 900;
}

.menuButtonText {
  padding-left: 10px;
  line-height: 0;
}

.menuSandwichIcon {
  display: inline-block;
  width: 40px;
}
